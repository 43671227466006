'use-strict'

//import 'react-app-polyfill/ie9'
//import 'babel-polyfill'
//import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import AppRouter from './router/AppRouter'
import * as serviceWorker from './serviceWorker'
import UnderMaintenance from 'pages/under-construction/UnderMaintenance'
import { AuthProvider } from '@stokr/components-library'
import { BrowserRouter } from 'react-router-dom'
import { AppProvider } from 'context/AppContext'

const app = (
  <BrowserRouter>
    <AuthProvider>
      <AppProvider>
        <AppRouter />
      </AppProvider>
    </AuthProvider>
    {/* <UnderMaintenance /> */}
  </BrowserRouter>
)

const container = document.getElementById('root')
export const root = createRoot(container)
root.render(app)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

// See you around guys, it was a pleasure working with you.
// Marcel Cruz - 12/08/2020 ✌️
