import React, { createContext, useContext, useState } from 'react'
import fetchDataPublic from 'api/fetch-data-public'

const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const [stokePosts, setStokePosts] = useState([])
  const [pressReleases, setPressReleases] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchStokePosts = async (filter) => {
    setIsLoading(true)
    setError(null)

    try {
      const { list } = await fetchDataPublic('stoke-post/get', {
        filter: filter || {
          status: 'Active',
        },
        data: [
          'category',
          'thumbnail_title',
          'image',
          'url',
          'stokepost_page_order',
          'publish_date',
          'meta_description',
          'readingtime',
        ],
      })

      // Separate posts by category
      const pressReleases = list.filter(
        (post) => post.category === 'PRESS RELEASE',
      )
      const regularPosts = list.filter(
        (post) => post.category !== 'PRESS RELEASE',
      )

      setStokePosts(regularPosts)
      setPressReleases(pressReleases)
    } catch (err) {
      setError('Failed to fetch stoke posts')
      console.error('Error fetching stoke posts:', err)
    } finally {
      setIsLoading(false)
    }
  }

  const value = {
    stokePosts,
    pressReleases,
    isLoading,
    error,
    fetchStokePosts,
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

// Custom hook for using the context
export const useAppContext = () => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider')
  }
  return context
}
